import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import Nav from "./components/Nav";
import Routes from "./Routes";

function App() {
  return (
    <Wrapper>
      <Helmet
        defaultTitle={"Nikita Lutsenko"}
        titleTemplate={"%s • Nikita Lutsenko"}
      />
      <MainContent transition={{ duration: 0.85 }} initial={false}>
        <Router>
          <Nav />
          <ContentWrapper>
            <AnimatePresence>
              <Routes/>
            </AnimatePresence>
          </ContentWrapper>
        </Router>
      </MainContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
  }
`;

const MainContent = styled(motion.div)`
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  overflow-y: auto;

  @media (max-width: 850px) {
    flex-direction: column;
    /* padding-top: 65px; */
  }
`;

const ContentWrapper = styled.div`
  margin-left: 15rem;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 850px) {
    margin-left: 0px;
    padding-top: 65px;
  }
`;

export default App;
