import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Stats from './pages/Stats';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    {/* <Route exact path="/where" component={Where} /> */}
    <Route exact path="/stats" component={Stats} />
    <Route exact path="/contact" component={Contact} />
    {/* <Route exact path="/presence" component={Presence} /> */}
  </Switch>
);

export default Routes;