async function fetchGitHubData() {
  const headers = {
    // Authorization: "Token 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",
  };
  // followers
  const url = "https://api.github.com/users/nlutsenko";
  const response = await fetch(url, { headers: headers });
  const json = await response.json();
  const followersCount = json.followers;
  // projects
  const response2 = await fetch("https://api.github.com/users/nlutsenko/repos?per_page=100", { headers: headers });
  const json2 = await response2.json();
  const reposCount = Object.keys(json2).length;
  // stars
  var starsCount = 0;
  json2.forEach((f: { stargazers_count: number }) => {
    starsCount += f.stargazers_count;
  });

  // commits
  const response3 = await fetch(
    "https://api.github.com/search/commits?q=author:nlutsenko&sort=author-date&order=desc",
    { headers: headers }
  );
  const json3 = await response3.json();  
  const commitsCount = json3.total_count
  return {
    followersCount,
    reposCount,
    starsCount,
    commitsCount,
  };
}

export default fetchGitHubData;
