import PageWrapper from "./PageWrapper";
import { useEffect, useState } from "react";
import StatBox from "../components/StatBox";
import { Helmet } from "react-helmet";
import { SimpleGrid } from "@chakra-ui/react";
import fetchGitHubData from "../data/github";

function formatCount(count: number): string {
  return count === 0 ? "..." : `${count}`;
}

const Stats = () => {
  const [data, setData] = useState({
    followersCount: 0,
    reposCount: 0,
    starsCount: 0,
    commitsCount: 0,
  });
  useEffect(() => {
    fetchGitHubData().then((d) => {
      setData(d);
    });
  }, []);
  return (
    <PageWrapper>
      <Helmet>
        <title>Stats</title>
      </Helmet>
      <h1>Stats</h1>
      <h3>Various Statistical Data</h3>
      <SimpleGrid minChildWidth="250px" spacing="20px">
        <StatBox
          name={"GitHub Followers"}
          url={"https://github.com/nlutsenko"}
          value={formatCount(data.followersCount)}
        />
        <StatBox
          name={"GitHub Repos"}
          url={"https://github.com/nlutsenko"}
          value={formatCount(data.reposCount)}
        />
        <StatBox
          name={"GitHub Stars"}
          url={"https://github.com/nlutsenko"}
          value={formatCount(data.starsCount)}
        />
        <StatBox
          name={"GitHub Commits"}
          url={"https://github.com/nlutsenko"}
          value={formatCount(data.commitsCount)}
        />
        <StatBox
          name={"GitHub Public Commits"}
          url={"https://github.com/nlutsenko"}
          value={"..."}
        />
        <StatBox
          name={"Tweets"}
          url={"https://twitter.com/NSUnimplemented"}
          value={"..."}
        />
        <StatBox
          name={"Facebook Posts"}
          url={"https://facebook.com/NSUnimplemented"}
          value={"..."}
        />
      </SimpleGrid>
    </PageWrapper>
  );
};

export default Stats;
