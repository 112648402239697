import { motion, PanInfo } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  GitHubLogo,
  MenuIcon,
  NavigationIcon,
  TwitterLogo,
  MailLogo,
  FacebookLogo,
  LinkedInLogo,
  InstagramLogo,
  XIcon,
} from "./Icons";
import useSound from "use-sound";

const pathnameOffsets: { [key: string]: number } = {
  "/": 0,
  "/where": 39,
  "/how": 78,
  "/contact": 192,
};

const Nav = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [playSwitchPageSound] = useSound("/p-static/sounds/switch-page.mp3");

  const [dragYOffset, setDragYOffset] = useState(0);
  const [openOnMobile, setOpenOnMobile] = useState(false);

  const dragConstraintsRef = useRef(null);

  useEffect(() => {
    if (openOnMobile) setOpenOnMobile(false);
    playSwitchPageSound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const pageIndicatorOffset = useMemo(
    () => (pathname ? pathnameOffsets[pathname] ?? -180 : 0),
    [pathname]
  );

  const pageIndicatorOffsetWithDecoration = useMemo(
    () => 71 + 33 + pageIndicatorOffset - dragYOffset,
    [pageIndicatorOffset, dragYOffset]
  );

  const onPageIndicatorDragEnd = useCallback(
    (_event, info: PanInfo) => {
      const goal = pageIndicatorOffset + info.offset.y;

      const closest = Object.entries(pathnameOffsets).reduce(
        ([prevPath, prevOffset], [curPath, curOffset]) => {
          return Math.abs(curOffset - goal) < Math.abs(prevOffset - goal)
            ? [curPath, curOffset]
            : [prevPath, prevOffset];
        }
      );

      if (closest[0] === pathname) return;

      setDragYOffset(dragYOffset + info.offset.y + info.velocity.y);
      history.push(closest[0]);
    },
    [history, pageIndicatorOffset, dragYOffset, pathname]
  );

  const toggleMobileMenu = useCallback(
    () => setOpenOnMobile(!openOnMobile),
    [openOnMobile]
  );

  return (
    <>
      <MobileHeader>
        <Title>
          <a href={"/"}>Nikita Lutsenko</a>
        </Title>
        {openOnMobile ? (
          <XIcon onClick={toggleMobileMenu} />
        ) : (
          <MenuIcon onClick={toggleMobileMenu} />
        )}
      </MobileHeader>
      <Container openOnMobile={openOnMobile}>
        {!openOnMobile ? (
          <PageIndicator
            whileHover={{ width: 3 }}
            drag="y"
            onDragEnd={onPageIndicatorDragEnd}
            dragConstraints={dragConstraintsRef}
            animate={{ top: pageIndicatorOffsetWithDecoration }}
          />
        ) : null}
        <Items>
          {!openOnMobile ? (
            <Row>
              <Title>
                <a href={"/"}>Nikita Lutsenko</a>
              </Title>
            </Row>
          ) : null}
          <Row>
            <Location
              target="_blank"
              href={`https://www.google.com/maps/place/San+Francisco+Bay+Area,+CA`}
              rel="noreferrer"
            >
              <NavigationIcon />
              {"SF Bay Area, CA"}
            </Location>
          </Row>

          <div ref={dragConstraintsRef}>
            <Page active={pathname === "/" ? 1 : 0} to="/">
              who am I
            </Page>
            <Page active={pathname === "/" ? 1 : 0} to="/">
              what I work on
            </Page>
            <Page active={pathname === "/" ? 1 : 0} to="/">
              public talks
            </Page>
            <Page active={pathname === "/" ? 1 : 0} to="/">
              what I use
            </Page>
            <Page active={pathname === "/stats" ? 1 : 0} to="/stats">
              stats
            </Page>
            <Page active={pathname === "/contact" ? 1 : 0} to="/contact">
              contact
            </Page>
          </div>
          <Icons>
            <a href="https://github.com/nlutsenko">
              <GitHubLogo />
            </a>
            <a href="https://twitter.com/NSUnimplemented">
              <TwitterLogo />
            </a>
            <a href="https://facebook.com/NSUnimplemented">
              <FacebookLogo />
            </a>
            <a href="https://instagram.com/nlutsenko/">
              <InstagramLogo />
            </a>
            <a href="https://www.linkedin.com/in/nsunimplemented/">
              <LinkedInLogo />
            </a>
            <a href="mailto:contact@nlutsenko.me">
              <MailLogo />
            </a>
          </Icons>
        </Items>
      </Container>
    </>
  );
};

const Container = styled.aside<{ openOnMobile: boolean }>`
  display: inline-block;
  box-sizing: border-box;
  flex-direction: column;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 15rem;
  border-right: 1px solid #101010;
  height: 100vh;

  @media (max-width: 850px) {
    display: ${({ openOnMobile }) => (openOnMobile ? "block" : "none")};
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(7px);
    z-index: 1;
    top: 65px;
    width: 100%;
    height: calc(100% - 65px);
  }
`;

const MobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  display: flex;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  height: 65px;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid #101010;
  flex-shrink: 0;
  z-index: 1;

  svg {
    margin-left: auto;
    cursor: pointer;
    color: #ccc;
  }

  @media (min-width: 850px) {
    display: none;
  }
`;

const PageIndicator = styled(motion.div)`
  width: 1px;
  height: 39px;
  background-color: #fff;
  position: absolute;
  right: -1px;
  cursor: pointer;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  padding: 10px 0px;
`;

const Location = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 19px;
  font-size: 14px;
  margin-bottom: 15px;
  user-select: none;

  color: white;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  svg:first-child {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    color: #4263eb;
  }
`;

const Page = styled(Link)<{ active: number }>`
  color: ${({ active }) => (active ? "#fff" : "#ccc")};
  padding: 10px 0px;
  display: flex;

  &:hover {
    /* background-color: #fff; */
    color: #fff;
  }
`;

const Icons = styled.div`
  margin-top: auto;
  color: #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 0;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #ccc;

    &:hover {
      color: #fff;
    }
  }
`;

export default Nav;
