import PageWrapper from "./PageWrapper";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";

const Home = () => {
  let startDate = DateTime.local(2013, 12, 16);
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });
  let elapsedDuration = startDate
    .diff(currentDate)
    .negate()
    .as("years")
    .toFixed(5);

  return (
    <PageWrapper forceReadableWidth>
      <h1>Who Am I</h1>
      <p>
        My name is <b>Nikita Lutsenko</b>.
      </p>
      <p>
        <b>I craft software</b>, <b>build cross-platform systems</b> and{" "}
        <b>create products at large scale.</b>
      </p>
      <p>
        For the last {elapsedDuration} years, I work at{" "}
        <b>
          <a href={"https://www.facebookcareers.com/"}>Meta</a>
        </b>{" "}
        (née Facebook) as a Software Engineer.
      </p>
      <p>
        At the current time, I primarily focus on{" "}
        <b>
          <a href="https://tech.fb.com/ar-vr/">
            Wearable & AR Interfaces
          </a>
        </b>, like <b><a href="https://www.meta.com/blog/quest/orion-ar-glasses-augmented-reality/">Orion</a></b>
        .
      </p>
      <br />
      <p>
        When not focused on the above, I invest my time into{" "}
        <b>
          <a href={"https://github.com/nlutsenko"}>open source</a>
        </b>
        , and <b>help startups</b> with <b>technical strategy</b> and team
        development.
      </p>
      <p>
        Got a big decision to make? Want to ensure your teams and tech stack is
        scalable?
        <br /> I'm always down to chat to new founders.
      </p>
    </PageWrapper>
  );
};

export default Home;
