import styled from "styled-components";
import {
  Flex,
  StatGroup,
} from "@chakra-ui/react";

const StatBox = ({
  url,
  name,
  value,
}: {
  url: string;
  name: string;
  value: string;
}) => {
  return (
      <A href={url} target="_blank" rel="noopener">
        <StatGroup
          border={`1px solid #101010}`}
          borderRadius={5}
          p={2}
          w="100%"
        >
          <Flex align="center" justifyContent="space-between">
            <Content>
              <h4>{name}</h4>
              <Text>{value}</Text>
            </Content>
          </Flex>
        </StatGroup>
      </A>
  );
};

const A = styled.a`
  color: inherit;

  &:hover {
    text-decoration: none !important;
  }
`;

const Text = styled.p`
  align: center;
  text-align: center;
`;

const Content = styled.div`
  padding: 1rem;
  box-sizing: border-box;
`;

export default StatBox;
