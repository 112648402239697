import { Helmet } from "react-helmet";
import PageWrapper from "./PageWrapper";

const C = () => (
  <PageWrapper forceReadableWidth>
    <Helmet>
      <title>Contact</title>
    </Helmet>
    <h1>Contact</h1>
    <p>
      I am generally responsive through most communication channels, <br />
      but <a href="https://t.me/NSUnimplemented">Telegram</a>,{" "}
      <a href={"https://twitter.com/messages/compose?recipient_id=131862734"}>
        Twitter DM
      </a>{" "}
      or <a href={"mailto:contact@nlutsenko.me"}>E-mail</a> would work fastest.
    </p>
    <h2>Other Ways to Reach Me</h2>
    <p>
      <b>Facebook:</b>{" "}
      <a href={"https://facebook.com/NSUnimplemented"}>
        facebook.com/NSUnimplemented
      </a>
    </p>
    <p>
      <b>Twitter:</b>{" "}
      <a href={"https://twitter.com/NSUnimplemented"}>@NSUnimplemented</a>
    </p>
    <p>
      <b>Instagram:</b>{" "}
      <a href={"https://instagram.com/nlutsenko/"}>instagram.com/nlutsenko</a>
    </p>
    <p>
      <b>GitHub:</b>{" "}
      <a href={"https://github.com/nlutsenko"}>github.com/nlutsenko</a>
    </p>
    <p>
      <b>LinkedIn:</b>{" "}
      <a href={"https://www.linkedin.com/in/nsunimplemented/"}>
        linkedin.com/in/nsunimplemented/
      </a>
    </p>
    <p>
      <b>Keybase:</b>{" "}
      <a href="https://keybase.io/nlutsenko">keybase.io/nlutsenko</a>
    </p>
    <p>
      <b>E-mail:</b>{" "}
      <a href={"mailto:contact@nlutsenko.me"}>contact@nlutsenko.me</a>
    </p>
    <p>
      <b>Telegram:</b>{" "}
      <a href="https://t.me/NSUnimplemented">@NSUnimplemented</a>
    </p>
    <p>
      <b>iMessage:</b> Coming soon...
    </p>
  </PageWrapper>
);

export default C;
